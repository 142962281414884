@use "scss/abstract/mixins" as *;

.LocationSwitcher {
  position: relative;
  z-index: 11;

  &Header {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    background-color: transparent;
    border: none;
    cursor: pointer;

    img {
      width: 4rem;
      height: 4rem;
    }

    h3 {
      color: var(--color-gray-800);
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.4rem;
      text-transform: capitalize;

      @include bp(smaller) {
        display: none;
      }
    }

    svg {
      color: var(--color-gray-800);
    }
  }

  &DisabledHeader {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

  &MenuList {
    list-style: none;
    position: absolute;
    top: 130%;
    background-color: #fff;
    border-radius: 0.4rem;
    border: 1px solid var(--color-gray-300);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 1.6rem 1.2rem;
    width: min(100%, 27.3rem);
    min-width: 20rem;
    max-height: 50rem;
    overflow-y: auto;
  }

  &MenuHeader {
    display: inline-block;
    text-transform: uppercase;
    color: var(--color-gray-600);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    margin-bottom: 0.8rem;
    text-transform: capitalize;
  }

  &MenuItem {
    display: inline-block;
    width: calc(100% + 2.4rem);
    margin: 0 -1.2rem;
  }

  &MenuItemButton {
    background-color: transparent;
    width: 100%;
    padding: 0.8rem 1.2rem;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    cursor: pointer;
    text-transform: capitalize;

    &:hover {
      background-color: var(--color-gray-100);
    }

    img {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}
