@use "scss/abstract/mixins" as *;

.SelectBox {
  display: none;
  position: relative;
  background-color: #fff;
  padding: 2.1rem 2.4rem;
  border-radius: 0.8rem;

  @media (max-width: 768px) {
    display: block;
  }
  // @include bp(largest) {
  //   display: block;
  // }

  .Select {
    & > button {
      width: min(100%, 20rem);
      padding: 1.1rem 1.6rem;
      background-color: var(--color-primary);
      color: #fff;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
      border-radius: 0.4rem;
      width: min(100%, 21.2rem);
      cursor: pointer;
    }
  }

  ul {
    list-style: none;
    position: absolute;
    border-radius: 0.4rem;
    max-height: 40vh;
    overflow-y: auto;
    width: min(100%, 21.2rem);
    border: 1px solid var(--color-gray-300);

    button {
      width: min(100%, 21.2rem);
      padding: 1.1rem 1.6rem;
      background-color: #fff;
      border: none;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.6rem;
      cursor: pointer;

      &:hover {
        background-color: var(--color-primary);
        color: #fff;
      }
    }
  }
}
