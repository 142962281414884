.input {
  width: 100%;
  height: 30rem;
  border-radius: var(--radius-medium);
  border: 1px solid var(--color-gray-light-1);
}
.inputField {
  width: 100%;
  height: 100%;
  padding: 0rem 1rem;
  outline: none;
  font-size: 1.6rem;
  border: transparent;
  background: transparent;
  &::placeholder {
    color: #9ca3af;
    font-size: 14px;
  }
}
