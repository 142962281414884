.UpdateTable {
  background-color: #fff;
  padding: 3.2rem;
  position: relative;
  margin-top: 2rem;
}

.DeleteButton {
  position: absolute;
  right: 3.2rem;
  color: var(--color-red-600);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.6rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  top: 1.5rem;
}

.Heading {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: var(--color-gray-800);
  margin-bottom: 3.2rem;
}

.SeatsInput,
.TableNumberInput,
.TableShapes {
  margin-bottom: 2.4rem;
  max-width: 61rem;
}

.SelectInput {
  margin-bottom: 8.8rem;
  max-width: 61rem;
}

.TableShapes {
  display: flex;
  gap: 2.4rem;
}

.TableRadioButton {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  cursor: pointer;

  input {
    visibility: none;
    position: absolute;
    left: -999rem;
  }

  &Icon {
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    border: 1px solid var(--color-gray-400);
  }

  & > input:checked + &Icon {
    background: #f9f5ff;
    border-color: var(--color-primary);

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background: var(--color-primary);
    }
  }

  &Shape {
    &Rounded,
    &Rectangle,
    &Circle,
    &Square {
      border: 1px solid var(--color-primary);
      background: #f3781f0d;
    }
    &Rounded {
      width: 9.6rem;
      height: 3.2rem;
      border-radius: 10rem;
    }
    &Rectangle {
      width: 6.8rem;
      height: 3.2rem;
    }
    &Circle,
    &Square {
      width: 4.8rem;
      height: 4.8rem;
    }
    &Circle {
      border-radius: 50%;
    }
  }
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;

  & > button {
    padding: 1.2rem 3.4rem;
  }

  .CancelBtn {
    background-color: transparent;
    color: var(--color-gray-600);
    border: none;
  }
}

.SelectBox {
  position: relative;
  max-width: 61rem;

  & > svg {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    color: var(--color-gray-600);
    pointer-events: none;
  }
}

.SelectInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
  border: 1px solid var(--color-gray-200);
  border-radius: 0.4rem;
  padding: 1.6rem 5rem 1.6rem 2.4rem;
  color: var(--color-gray-600);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6rem;
}

.CustomModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(100vw, 40rem);

  & > p:first-of-type {
    margin-top: 2rem;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.8rem;
    color: var(--color-gray-900);
  }
  & > p:last-of-type {
    color: var(--color-gray-500);
    margin-top: 0.8rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    margin-bottom: 3.2rem;
    text-align: center;
  }

  .ModalActions {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    width: 100%;
  }

  .ModalProceedBtn,
  .ModalCancelBtn {
    display: block;
    padding: 1rem;
    border-radius: 0.4rem;
    width: 100%;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.4rem;
    cursor: pointer;
  }

  .ModalCancelBtn {
    border: 1px solid var(--color-gray-300);
    color: var(--color-gray-500);
    flex: 1;
    background-color: transparent;
  }

  .ModalProceedBtn {
    flex: 1;
    background-color: var(--color-red-600);
    color: #fff;
    border: 1px solid var(--color-red-600);
  }
}
