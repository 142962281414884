.ModalHeaders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // gap: 4.2rem;
  color: var(--color-gray-800);
  margin-bottom: 3rem;

  & > span {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.6rem;
  }
}

.PartySize {
  &Modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 23.5%;
    padding: 3rem 2rem;

    @media (max-width: 1168px) {
      width: 33.5%;
    }
    @media (max-width: 768px) {
      width: 45%;
    }
  }
  &Btns {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    border-radius: 0.8rem;
    background: #f3781f1a;
    padding: 1rem 1.6rem;
    margin-bottom: 3rem;

    & > button {
      background-color: transparent;
      border: none;
      color: var(--color-primary);
      cursor: pointer;
    }
  }

  &Text {
    color: var(--color-gray-800);
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 2.4rem;
  }
}

.ModalActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ModalCancelBtn,
.ModalNextBtn {
  padding: 1.2rem 2.4rem;
  background-color: transparent;
  border: none;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: var(--color-gray-600);
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.ModalNextBtn {
  background-color: var(--color-primary);
  color: #fff;
}

.PartySizeText {
  width: 25px;
  background: #ffffff1a;
  border: none;
  :focus-visible {
    outline: 0;
  }
}
