.LoginButton {
  color: #fff;
  font-size: 1.6rem;
  width: 100%;
  padding: 1.6rem;
  border: none;
  border-radius: var(--radius-medium);
  background-color: var(--color-auth-screens);
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: var(--color-auth-screens);
  }
}
