@use "scss/abstract/mixins" as *;

.TimeBox {
  display: flex;
  align-items: center;
  padding: 1rem 1.6rem;
  border-radius: 2.5rem;
  gap: 0.8rem;
  color: var(--color-gray-600);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.2rem;
  margin-left: auto;
  margin-right: 2.4rem;

  svg {
    color: var(--color-gray-800);
  }

  @include bp(small-1) {
    display: none;
  }
}

html[dir="rtl"] {
  .TimeBox {
    margin-right: auto;
    margin-left: 2.4rem;
  }
}
