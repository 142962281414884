@import "./scss/main.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter-Black";
  src: local("Inter-Black"),
    url(./assets/fonts/Inter-Black.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Bold";
  src: local("Inter-Bold"),
    url(./assets/fonts/Inter-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-ExtraBold";
  src: local("Inter-ExtraBold"),
    url(./assets/fonts/Inter-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Medium";
  src: local("Inter-Medium"),
    url(./assets/fonts/Inter-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular"),
    url(./assets/fonts/Inter-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: local("Inter-SemiBold"),
    url(./assets/fonts/Inter-SemiBold.ttf) format("truetype");
}
