.PaymentChannel {
  display: flex;
  align-items: center;
  gap: 7.2rem;
}

.Tenders,
.Channel {
  flex: 1;
  &Headings {
    display: flex;
    align-items: center;
    margin-bottom: 3.2rem;

    color: var(--color-gray-800);
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.4rem;
  }

  &Heading {
    &Lg1 {
    }
    &Lg2 {
      margin-left: auto;
    }
  }

  &List {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 3.2rem;
  }
  &ListItem,
  &ListItemSelected {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 2px solid transparent;
    width: 16.5rem;
    height: 16.5rem;
    background-color: #fff;
    border-radius: 0.8rem;
    box-shadow: 0px 10px 15px -3px #0000001a;
    box-shadow: 0px 4px 6px -2px #0000000f;
    color: var(--color-gray-400);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4rem;
    cursor: pointer;

    & > img {
      margin-bottom: 3.2rem;
    }

    & > svg {
      position: absolute;
      right: 1.6rem;
      top: 1rem;
      color: var(--color-primary);
    }

    & > span {
      position: absolute;
      bottom: 1.2rem;
    }
  }

  &ListItemSelected {
    border: 2px solid var(--color-primary);
  }
}

.VerticalBreaker {
  width: 1px;
  height: 100%;
  border: none;
  background-color: var(--color-gray-300);
}

.ActionBtns {
  display: flex;
  margin-left: auto;
  gap: 3rem;
  // margin-top: 10rem;
}

.CancelBtn {
  border: none;
  background-color: transparent;
  color: var(--color-gray-600);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.6rem;
  cursor: pointer;
}

.FlexBox {
  display: flex;
  flex-direction: column;
}
