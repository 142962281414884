.Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // backdrop-filter: blur(2px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dark {
  background-color: rgba($color: #000, $alpha: 0.6);
}

.Light {
  background-color: rgba($color: #fff, $alpha: 0.6);
}
