.CustomModal {
  width: min(100%, 75rem);
  padding: 0;
}

.ModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;

  & p {
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.content {
  padding: 1rem 2.5rem;
}

.input {
  width: 100%;
  height: 30rem;
  border-radius: var(--radius-medium);
  border: 1px solid var(--color-gray-light-1);
}

.inputField {
  width: 100%;
  padding: 1.6rem 2.4rem;
  outline: none;
  font-size: 1.6rem;
  border: transparent;

  &::placeholder {
    color: #9ca3af;
    font-size: 14px;
  }
}

.btnControl {
  display: flex;
  flex-direction: row;
  width: 50%;
}









.TableRadioButton {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;

  input {
    visibility: none;
    position: absolute;
    left: -999rem;
  }

  &Icon {
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    border: 1px solid var(--color-gray-400);
  }

  & > input:checked + &Icon {
    background: #f9f5ff;
    border-color: var(--color-primary);

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background: var(--color-primary);
    }
  }
}




