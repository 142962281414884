.Dish {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4rem 1.8rem;
  background-color: #fff;
  border-radius: 0.6rem;
  text-align: center;
  cursor: pointer;
  max-height: 300px;

  &Image {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: var(--color-gray-light-1);
    object-fit: cover;
    object-position: center;
  }
  &Name {
    width: 100%;
    color: var(--color-gray-600);
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.4rem;

    @media (max-width: 768px) {
      font-size: 1rem;
    }

    &:first-of-type {
      margin-top: 1.2rem;
      margin-bottom: 0.6rem;
    }
    &:nth-of-type(2) {
      margin-bottom: 1.6rem;
    }
  }
  &Price {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.6rem;
    color: var(--color-gray-800);
    margin-bottom: 1.3rem;
  }
  &Stock {
    font-size: 1.2rem;
    color: var(--color-gray-light-2);

    &:first-child {
      margin-bottom: 0.6rem;
    }
  }
}
