.CustomModal {
  width: min(100%, 57rem);
  padding: 0;
}

.ModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(185, 28, 28, 0.1);
  padding: 2.5rem;
  & p {
    font-size: 2rem;
    font-weight: 500;
  }
}
.content {
  padding: 2.5rem;
}
.input {
  width: 100%;
  height: 30rem;
  border-radius: var(--radius-medium);
  border: 1px solid var(--color-gray-light-1);
}
.inputField {
  width: 100%;
  padding: 1.6rem 2.4rem;
  outline: none;
  font-size: 1.6rem;
  border: transparent;
  height: 100%;
  &::placeholder {
    color: #9ca3af;
    font-size: 14px;
  }
}
.btnControl {
  display: flex;
  flex-direction: row;
  width: 50%;
}
