.CustomModal {
  width: min(100%, 95rem);
  padding: 0;
  margin-right: auto;
  margin-left: 22%;
  border-radius: 16px;
  @media (max-width: 1200px) {
    width: min(100%, 70rem);
    margin-left: 25%;
  }
  @media (max-width: 900px) {
    width: min(100%, 100rem);
    // margin-left: 25%;
    margin-right: 10%;
  }
  @media (max-width: 850px) {
    width: min(100%, 100rem);
    // margin-left: 25%;
    margin-right: 10%;
    margin-left: 10%;
  }
}

.ModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(185, 28, 28, 0.1);
  padding: 2.5rem;
  & p {
    font-size: 2rem;
    font-weight: 500;
  }
}
.content {
  padding: 2.5rem;
}
.input {
  width: 100%;
  height: 30rem;
  border-radius: var(--radius-medium);
  border: 1px solid var(--color-gray-light-1);
}
.inputField {
  width: 100%;
  padding: 1.6rem 2.4rem;
  outline: none;
  font-size: 1.6rem;
  border: transparent;
  &::placeholder {
    color: #9ca3af;
    font-size: 14px;
  }
}
.btnControl {
  display: flex;
  flex-direction: row;
  width: 50%;
}
.ConfirmAndPrintBtn {
  margin-top: 1.2rem;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7rem;
}
.CancelBtn {
  background-color: #f3f4f6;
  margin-top: 1.2rem;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7rem;
  border: 1px solid #f3f4f6;
  color: #4b5563;
}
