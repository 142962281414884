.container {
  @apply relative w-full overflow-x-auto;
}

.table {
  @apply min-w-full text-left text-sm;

  &__header {
    &_row {
      @apply border-b border-t  border-gray-300 font-semibold text-gray-500 text-lg;
    }

    &_cell {
      @apply whitespace-nowrap p-4;
    }
  }

  &__row {
    @apply border-b  bg-transparent text-gray-600 text-lg;
  }

  &__cell {
    @apply whitespace-nowrap p-4;
  }
}
