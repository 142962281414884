.receipt {
  width: 280px;
  margin: 10px 0;
  padding: 20px 15px;
  background: white;
  font-family: 'Courier New', monospace;
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.header {
  text-align: center;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.logo {
  width: 60px;
  height: 60px;
  margin: 0 auto 10px;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brandInfo {
  margin-top: 30px; // Added space from the top
  input {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    border: none;
    font-size: 14px;
    font-weight: bold;
    margin: 2px 0;
    padding: 3px;
    background: transparent;
    
    &::placeholder {
      color: #999;
    }
  }
}

.taxInfo, .orderDetails, .paymentDetails {
  margin: 10px 0;
  padding: 5px 0;
  border-bottom: 1px dashed #ccc;
}

.fieldGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  
  label {
    font-size: 12px;
    min-width: 100px;
  }

  input, select {
    width: 150px;
    border: none;
    background: transparent;
    text-align: right;
    font-family: 'Courier New', monospace;
    font-size: 12px;
    padding: 2px;
    
    &:focus {
      outline: none;
      background: #f9f9f9;
    }
  }
}

.invoiceTypes {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0;
  font-size: 11px;
  
  span {
    margin: 2px 0;
  }
}

.barcode {
  text-align: center;
  margin-top: 15px;
  padding: 10px;
  border-top: 1px dashed #ccc;
  font-size: 11px;
}

.paymentDetails {
  .fieldGroup {
    input[type="number"] {
      font-weight: bold;
    }
  }
}

input:required, select:required {
  &::after {
    content: '*';
    color: #ff0000;
    margin-left: 2px;
  }
}

.splitReceiptsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 20px;
  margin-top: 20px;
}

@page {
  size: auto;   /* auto ya aap specific size bhi de sakte hain jaise '80mm 200mm' */
  margin: 0mm;  /* page ke margins 0 kar diye */
}

@media print {
  @page {
    size: auto;
    margin: 0mm;
  }

  body {
    margin: 0;
    padding: 0;
  }

  .receipt {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 20px;
    page-break-after: always; /* har receipt ke baad new page */
  }

  .printButton {
    display: none;
  }
} 

.printButton {
  margin-top: 20px;
  text-align: center;

  button {
    background-color: #f3781f;  // professional green color
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center; /* Center the text horizontally */
    gap: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    &:hover {
      background-color: #e76f19;
    }

    &:active {
      transform: translateY(1px);
    }
  }
}