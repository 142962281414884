// $breakpoints: (
//   largest: 86.625em,
//   larger: 78.125em,
//   large: 65.625em,
//   medium: 59.375em,
//   small-1: 53.125em,
//   small: 43.75em,
//   smaller: 35.937em,
//   smallest: 29.6875em,
// );

@use "./variables" as *;

@mixin bp($breakpoint) {
  $size: map-get($breakpoints, $breakpoint);

  @media (max-width: $size) {
    @content;
  }
}
