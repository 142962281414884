.CategoriesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > h2:nth-child(2) {
    margin-left: auto;
    margin-right: 1.6rem;
  }
}

// .Categories {
//   display: grid;
//   // grid-template-columns: repeat(auto-fit, minmax(31rem, 31.5rem));
//   grid-template-columns: repeat(2, minmax(0, 1fr));
//   grid-gap: 2.8rem;
//   margin-top: 3rem;
//   @media (min) {

//   }
// }

.categoryContainer {
  background: linear-gradient(
    238.73deg,
    #d1d5db -4.04%,
    #f3f4f6 101.35%,
    #9ca3af 101.35%
  );
}

.Category {
  // padding: 11.8rem;
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  //   background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%),
  //     url(../../../../../assets/img/drink.jpg);
  background-size: cover;
  cursor: pointer;

  h3 {
    color: white;
    font-size: 1.5rem;
    width: 90%;
    text-align: center;
    text-transform: capitalize;
  }
}

.Input {
  width: 30%;
}

.NoCategoryFound {
  color: var(--color-gray-600);
}

.LoaderContainer {
  width: 83vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Error {
  width: 83vw;
  text-align: center;
  color: red;
}
