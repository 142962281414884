@import "../../scss/abstract/mixins";

.LoginPage {
  display: flex;
  width: 100%;
  height: 100vh !important;
  @media (max-width: 850px) {
    height: 110vh !important;
  }
}

.LoginForm,
.SideImage {
  width: 50%;
}

.LoginForm {
  padding: 4rem 12rem;

  @include bp(large) {
    width: 100%;
  }

  @include bp(small) {
    padding: 4rem 6rem;
  }

  .LoginHeader {
    font-weight: 600;
    font-size: 3.6rem;
    line-height: 3.6rem;
    margin-top: 8rem;
    margin-bottom: 2.4rem;
  }

  .LoginParagraph {
    font-size: 1.6rem;
    margin-bottom: 4.8rem;
    color: var(--color-gray-600);
    width: 35rem;

    @include bp(smaller) {
      width: auto;
    }
  }

  .NoAccountDiv {
    text-align: center;
  }
}

.SideImage {
  position: fixed;
  height: 100vh;
  right: 0;
  background: var(--color-auth-screens) url(../../assets/svg/spiral.png);
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;

  @include bp(large) {
    display: none;
  }
}
