.PrimaryInput {
  display: flex;
  flex-direction: column;
  color: var(--color-gray-600);

  label {
    margin-bottom: 1.6rem;
  }

  input {
    padding: 1.6rem 2.4rem;
    border-radius: var(--radius-medium);
    border: 1px solid var(--color-gray-light-1);
    outline: none;
    font-size: 1.6rem;

    &:focus {
      border: 1px solid var(--color-primary);
    }
  }
}
