.IconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  border-radius: 0.4rem;
  padding: 1rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.6rem;
  transition: transform 0.3s;
  cursor: pointer;

  svg {
    height: 2rem;
    width: 2rem;
  }
}

.Primary {
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary);
  color: #fff;
}

.Secondary {
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary-light);
  color: var(--color-primary);
  transition: background-color 0.2s ease-out;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;

  &:hover {
    background-color: var(--color-primary);
    color: #fff;
  }
}

.Disabled {
  background-color: var(--color-gray-light-2);
  border: 1px solid var(--color-gray-light-2);
  transform: scale(0.92);
}
