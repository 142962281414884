.Form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .ModalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 7px;
    & strong {
      font-size: 19px;
      font-weight: 700;
      color: #1f2937;
    }
    & p {
      font-size: 16px;
      font-weight: 400;
      color: #4b5563;
    }
  }
  .PinInput {
    width: 100px;
    height: 65px;
    text-align: center;
    font-size: 25px;
    border: 1px solid #e2e8f0;
    border-radius: 5px;
  }

  .ActiveInput {
    border: 1px solid #4b5563;
  }

  .ErrorInput{
    border: 1px solid red;
  }
}
