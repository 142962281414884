.POSLayout {
  flex: 1;
  display: flex;
  flex-direction: row;
  min-width: 100vw !important;
  // min-height: 100vh;
  justify-content: space-between !important;
}

.Container {
  flex: 1 !important;
  height: 100vh;
  // min-height: 100vh;
  // margin-left: 0rem !important;
  max-width: 85% !important;
  // margin-left: 70% !important;
  // margin-left: var(--sidebar-max-width) !important;
  // margin-left: 20rem !important;
  @media (max-width: 850px) {
    margin-left: 0rem !important;
    max-width: 100% !important;
  }
}
.Collapsed_Container {
  flex: 1;
  height: 100vh;
  // min-height: 100vh;
  margin-left: var(--sidebar-collapsed-max-width) !important;
}

.Main {
  padding: 0rem 0rem 3.7rem 2rem !important;
  // padding: 3.7rem 2.5rem 3.7rem 2rem !important;
  background-color: var(--color-gray-light-1);
  overflow-y: auto;
  // margin-top: 10rem;
  @media (max-width: 1200px) {
    padding: 0rem 0rem 3.7rem 2rem !important;
    // padding: 3.7rem 3.5rem 3.7rem 2rem !important;
  }
  @media (max-width: 850px) {
    // padding: 2.5rem 3.2rem 2.4rem 2rem !important;
    padding: 0rem 0rem 2.4rem 2rem !important;
  }
}
.Collapsed_Main {
  padding: 0rem 0rem 2.5rem 3.2rem !important;
  // padding: 3.7rem 2.5rem 3.7rem 2.5rem !important;
  background-color: var(--color-gray-light-1);
  overflow-y: auto;
  min-height: 85vh;
}

.NoSupportScreen {
}

.NoSupportScreenContent {
  margin-top: 1.9rem;
  display: grid;
  place-content: center;
  place-items: center;
  text-align: center;
  height: 70vh;
  h2 {
    margin-top: 5rem;
    margin-bottom: 3.9rem;
    font-family: Inter;
    font-size: 2.4rem;
    line-height: 2.4rem;
    color: var(--color-gray-800);
  }

  p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    color: var(--color-gray-600);
  }
}

.NoSupportScreenContentImg {
  position: relative;
  svg {
    position: absolute;
    right: -1rem;
    bottom: 0;
  }
}
