.Drawer {
  z-index: 1000;
  padding: 3.2rem 2rem;
  min-height: 100vh;
  overflow-y: auto;
  max-width: var(--sidebar-max-width);
  background-color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  right: 0;
  &TogggleBtn {
    background-color: transparent;
    border: none;
    font-size: 2.4rem;
    color: var(--color-gray-50);
    text-align: end;
    cursor: pointer;
  }

  &Header {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 1.8rem;
    grid-row-gap: 0.4rem;
    margin-bottom: 3.8rem;
    align-items: center;

    svg {
      grid-row: 1 / span 2;
      color: var(--color-gray-50);
      transition: margin 0.2s;
    }

    label {
      text-transform: uppercase;
      color: var(--color-gray-300);
      font-family: Inter;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
    }
    a {
      display: inline-block;
      color: var(--color-gray-100);
      font-family: Inter;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.6rem;
      text-decoration: none;
      &:hover {
        color: var(--color-primary);
      }
    }
  }
}

.DrawerNav {
  &List {
    list-style: none;
    padding: 1.8rem 0;
  }
  &Item {
    display: block;
    width: 100%;
  }
  &Link {
    transition: padding 0.2s, background-color 0.1s;

    &:hover svg,
    &:hover span {
      color: var(--color-primary);
    }
  }

  &Link,
  &LinkActive {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 1.4rem;
    padding: 2rem 0;

    svg {
      color: var(--color-gray-50);
    }

    span {
      color: var(--color-gray-100);
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.6rem;
    }
  }

  &LinkActive {
    background-color: var(--color-primary);
    padding: 1.2rem;
  }
}

.Breaker,
.LinkBreaker {
  display: inline-block;
  border: none;
  width: 100%;
  background-color: var(--color-gray-700);
  height: 1px;
}

.LinkBreaker {
  margin: 1.8rem 0;
}

.UserBox {
  margin-top: auto;
  hr {
    margin-bottom: 3.2rem;
  }

  &Label,
  &Status {
    color: var(--color-gray-300);
    text-transform: uppercase;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  &Label {
    display: block;
    font-weight: 500;
    margin-bottom: 0.8rem;
  }
  &Status {
    display: inline-block;
    font-weight: 600;
    letter-spacing: 0.06em;
    padding: 1rem;
    background-color: var(--color-gray-700);
    margin-bottom: 3.2rem;
  }
}

.User {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  color: var(--color-gray-50);
  cursor: pointer;

  &Img {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    transition: margin 0.2s;
  }

  &Name {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.6rem;
  }

  svg {
    margin-left: auto;
  }
}

html[dir="rtl"] {
  .Drawer {
    right: 0;
  }

  .User svg {
    margin-left: 0;
    margin-right: auto;
  }
}
