.Receipt {
  margin-top: 1.9rem;
  padding: 1.5rem 1.2rem 2.4rem 1.2rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.4rem;
  // max-height: 60vh;
  overflow-y: auto;
  // display: flex;
  align-items: center;
  flex-direction: column;
  // font-family: "Inter";
  font-family: "Merchant";
  text-align: center;
  font-size: larger;
}
.ReceiptTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0.7rem;

  th {
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 1rem;
    color: #000;
    padding-bottom: 1.2rem;
    font-family: "Merchant";
    padding-right: 0.5rem;
  }
  tr {
    padding-top: 15px !important;
  }

  td {
    color: #000;
    font-size: 1.9rem;
    font-weight: 400;
    line-height: 1rem;
    padding-bottom: 0.4rem;
    vertical-align: top;
    // border-bottom: 1px dashed var(--color-gray-200);
    font-family: "Merchant";
    padding-right: 0.5rem;
    padding-top: 0.4rem;
  }

  th,
  td {
    text-align: right;

    &:first-child {
      text-align: left;
    }
  }
}

.ItemNameCell {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.8rem;

  & > span:last-of-type {
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
  }
}