.GetStarted {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  // height: 100vh;
  width: 70rem;
  & > img {
    margin-bottom: 2rem;
  }
  div{
    div{
      div{
        width: 100%;
        input{
          width: 100%;
        }
      }
    }
  }

  &Heading {
    flex-direction: column;
    gap: 1.2rem;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.4rem;
    color: var(--color-gray-800);
    margin-bottom: 4rem;
    margin-top: 2rem;
  }
  &Text {
    color: var(--color-gray-500);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    text-align: center;
  }

  &Text:last-of-type {
    margin-bottom: 2.4rem;
  }

  &Actions {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    width:  100%;
    .AddBox > button,
    & > button {
      padding: 1rem 1.4rem;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      border-radius: 0.4rem;
      cursor: pointer;
    }
  }
}
.SARtext {
  font-size: large;
}
.AddBox {
  position: relative;
}

.AddOptions {
  list-style: none;
  position: absolute;
  border-radius: 0.4rem;

  & > li {
    display: inline-block;
  }

  button {
    width: 16rem;
    padding: 1.1rem 0;
    background-color: #fff;
    border: none;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4rem;
    white-space: nowrap;
    color: var(--color-gray-600);
    cursor: pointer;

    &:hover {
      background-color: var(--color-gray-200);
    }
  }
}

.AddBtn,
.AddBtnTop {
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 16rem;
  height: 48px;
  cursor: pointer;
}

.AddBtnTop {
  padding: 1.4rem 1.6rem;
  border-radius: 0.4rem;
  // margin-left: 1.6rem;

  font-size: 1.6rem;
}
.inputDateContainer {
  background: rgba(185, 28, 28, 0.1);
  width: 200px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
}
.inputDate {
  width: 100%;
  height: 100%;
  padding: 0px 8px 0px 8px;
  background: rgba(185, 28, 28, 0.1);
  border-radius: 4px;
  font-size: 15px;
  color: #1f2937;
}
