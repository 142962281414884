.Table,
.SelectedTable {
  border: 1px solid transparent;
  padding: 2.4rem 4rem;
  background-color: #fff;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 27.3rem;
  cursor: pointer;

  &TopNumber {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8rem;
  }

  &TopNumber,
  &Status,
  &Size {
    display: inline-block;
    border-radius: 5rem;
    background-color: var(--color-gray-50);
    padding: 0.2rem 0.8rem;
  }

  &TopNumber {
    color: var(--color-gray-600);
    margin-bottom: 0.8rem;
  }
  &Status {
    position: relative;
    // padding: 0.2rem 0.8rem 0.2rem 2rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.6rem;
    color: var(--color-gray-700);

    &Dot {
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background-color: var(--color-gray-600);
    }
  }
  &Size {
    background: #f3781f0d;
    color: var(--color-primary);
  }

  &IconSizeUpto2,
  &IconSizeUpto3,
  &IconSizeUpto4,
  &IconSizeUpto6,
  &IconSizeUpto8,
  &IconSizeUpto10,
  &IconSizeUpto12 {
    &square {
      width: 9rem;
      height: 9rem;
      border-radius: 5px;
    }
    &circle {
      width: 9.6rem;
      height: 9.6rem;
      border-radius: 50%;
    }
    &rectangle {
      width: 15rem;
      height: 7.2rem;
    }
    &round {
      width: 15rem;
      height: 7.2rem;
      border-radius: 5rem;
    }
  }

  &IconSizeUpto2,
  &IconSizeUpto3,
  &IconSizeUpto4 {
    &square,
    &circle,
    &rectangle,
    &round {
      position: relative;
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-gray-100);

      & > span {
        position: absolute;
        width: 0.2rem;
        height: 1.6rem;
        background-color: var(--color-primary);
        border-radius: 5rem;
      }

      & > span:nth-child(2) {
        left: -0.4rem;
      }

      & > span:nth-child(3) {
        right: -0.4rem;
      }

      & > span:nth-child(4),
      & > span:nth-child(5),
      & > span:nth-child(6),
      & > span:nth-child(7),
      & > span:nth-child(8),
      & > span:nth-child(9) {
        rotate: 90deg;
      }

      & > span:nth-child(4),
      & > span:nth-child(6),
      & > span:nth-child(8) {
        top: -1.2rem;
      }

      & > span:nth-child(6),
      & > span:nth-child(7) {
        left: 2.5rem;
      }

      & > span:nth-child(9),
      & > span:nth-child(8) {
        right: 2.5rem;
      }

      & > span:nth-child(5),
      & > span:nth-child(7),
      & > span:nth-child(9) {
        bottom: -1.2rem;
      }
    }
  }

  &IconSizeUpto6 {
    &square,
    &circle,
    &rectangle,
    &round {
      position: relative;
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-gray-100);

      & > span {
        position: absolute;
        width: 0.2rem;
        height: 1.6rem;
        background-color: var(--color-primary);
        border-radius: 5rem;
      }

      & > span:nth-child(2) {
        left: -0.4rem;
      }

      & > span:nth-child(3) {
        right: -0.4rem;
      }

      & > span:nth-child(4),
      & > span:nth-child(5) {
        right: 40%;
      }

      & > span:nth-child(4),
      & > span:nth-child(5),
      & > span:nth-child(6),
      & > span:nth-child(7) {
        rotate: 90deg;
      }

      & > span:nth-child(4),
      & > span:nth-child(6) {
        top: -1.2rem;
      }

      & > span:nth-child(6),
      & > span:nth-child(7) {
        left: 40%;
      }

      & > span:nth-child(5),
      & > span:nth-child(7) {
        bottom: -1.2rem;
      }
    }
  }

  &IconSizeUpto8 {
    &square,
    &circle,
    &rectangle,
    &round {
      position: relative;
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-gray-100);

      & > span {
        position: absolute;
        width: 0.2rem;
        height: 1.6rem;
        background-color: var(--color-primary);
        border-radius: 5rem;
      }

      & > span:nth-child(2) {
        left: -0.4rem;
      }

      & > span:nth-child(3) {
        right: -0.4rem;
      }

      & > span:nth-child(8),
      & > span:nth-child(9) {
        right: 30%;
      }

      & > span:nth-child(4),
      & > span:nth-child(5),
      & > span:nth-child(6),
      & > span:nth-child(7),
      & > span:nth-child(8),
      & > span:nth-child(9) {
        rotate: 90deg;
      }

      & > span:nth-child(4),
      & > span:nth-child(6),
      & > span:nth-child(8) {
        top: -1.2rem;
      }

      & > span:nth-child(6),
      & > span:nth-child(7) {
        left: 30%;
      }

      & > span:nth-child(5),
      & > span:nth-child(7),
      & > span:nth-child(9) {
        bottom: -1.2rem;
      }
    }
  }
  &IconSizeUpto10 {
    &square,
    &circle,
    &round {
      position: relative;
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-gray-100);

      & > span {
        position: absolute;
        width: 0.2rem;
        height: 1.6rem;
        background-color: var(--color-primary);
        border-radius: 5rem;
      }

      & > span:nth-child(2),
      & > span:nth-child(3) {
        top: 22%;
      }

      & > span:nth-child(11),
      & > span:nth-child(10) {
        bottom: 22%;
      }

      & > span:nth-child(2),
      & > span:nth-child(10) {
        left: -0.4rem;
      }

      & > span:nth-child(3),
      & > span:nth-child(11) {
        right: -0.4rem;
      }

      & > span:nth-child(8),
      & > span:nth-child(9) {
        right: 30%;
      }

      & > span:nth-child(4),
      & > span:nth-child(5),
      & > span:nth-child(6),
      & > span:nth-child(7),
      & > span:nth-child(8),
      & > span:nth-child(9) {
        rotate: 90deg;
      }

      & > span:nth-child(4),
      & > span:nth-child(6),
      & > span:nth-child(8) {
        top: -1.2rem;
      }

      & > span:nth-child(6),
      & > span:nth-child(7) {
        left: 30%;
      }

      & > span:nth-child(5),
      & > span:nth-child(7),
      & > span:nth-child(9) {
        bottom: -1.2rem;
      }
    }
  }
  &IconSizeUpto10 {
    &rectangle {
      position: relative;
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-gray-100);

      & > span {
        position: absolute;
        width: 0.2rem;
        height: 1.6rem;
        background-color: var(--color-primary);
        border-radius: 5rem;
      }

      // & > span:nth-child(2),
      // & > span:nth-child(3) {
      //   top: 22%;
      // }

      // & > span:nth-child(11),
      // & > span:nth-child(10) {
      //   bottom: 22%;
      // }

      & > span:nth-child(3) {
        right: -0.4rem;
      }

      & > span:nth-child(2) {
        left: -0.4rem;
      }

      & > span:nth-child(8),
      & > span:nth-child(9) {
        right: 40%;
      }

      & > span:nth-child(10),
      & > span:nth-child(11) {
        right: 25%;
      }

      & > span:nth-child(4),
      & > span:nth-child(5) {
        left: 40%;
      }

      & > span:nth-child(6),
      & > span:nth-child(7) {
        left: 25%;
      }

      & > span:nth-child(4),
      & > span:nth-child(5),
      & > span:nth-child(6),
      & > span:nth-child(7),
      & > span:nth-child(8),
      & > span:nth-child(9),
      & > span:nth-child(10),
      & > span:nth-child(11) {
        rotate: 90deg;
      }

      & > span:nth-child(4),
      & > span:nth-child(6),
      & > span:nth-child(8),
      & > span:nth-child(10) {
        top: -1.2rem;
      }

      & > span:nth-child(5),
      & > span:nth-child(7),
      & > span:nth-child(9),
      & > span:nth-child(11) {
        bottom: -1.2rem;
      }
    }
  }
  &IconSizeUpto12 {
    &rectangle,
    &round {
      position: relative;
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-gray-100);

      & > span {
        position: absolute;
        width: 0.2rem;
        height: 1.6rem;
        background-color: var(--color-primary);
        border-radius: 5rem;
      }

      & > span:nth-child(2),
      & > span:nth-child(3) {
        top: 22%;
      }

      & > span:nth-child(11),
      & > span:nth-child(10) {
        bottom: 22%;
      }

      & > span:nth-child(2),
      & > span:nth-child(10) {
        left: -0.4rem;
      }

      & > span:nth-child(3),
      & > span:nth-child(11) {
        right: -0.4rem;
      }

      & > span:nth-child(8),
      & > span:nth-child(9) {
        right: 27%;
      }

      & > span:nth-child(4),
      & > span:nth-child(5) {
        right: 42%;
      }

      & > span:nth-child(6),
      & > span:nth-child(7) {
        left: 40%;
      }

      & > span:nth-child(12),
      & > span:nth-child(13) {
        left: 25%;
      }
      & > span:nth-child(4),
      & > span:nth-child(5),
      & > span:nth-child(6),
      & > span:nth-child(7),
      & > span:nth-child(8),
      & > span:nth-child(9),
      & > span:nth-child(12),
      & > span:nth-child(13) {
        rotate: 90deg;
      }

      & > span:nth-child(4),
      & > span:nth-child(6),
      & > span:nth-child(8),
      & > span:nth-child(12) {
        top: -1.2rem;
      }

      & > span:nth-child(5),
      & > span:nth-child(7),
      & > span:nth-child(9),
      & > span:nth-child(13) {
        bottom: -1.2rem;
      }
    }
  }
  &IconSizeUpto12 {
    &square,
    &circle {
      position: relative;
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-gray-100);

      & > span {
        position: absolute;
        width: 0.2rem;
        height: 1.6rem;
        background-color: var(--color-primary);
        border-radius: 5rem;
      }

      & > span:nth-child(2),
      & > span:nth-child(3) {
        top: 22%;
      }

      & > span:nth-child(11),
      & > span:nth-child(10) {
        bottom: 22%;
      }

      & > span:nth-child(2),
      & > span:nth-child(10),
      & > span:nth-child(12) {
        left: -0.4rem;
      }

      & > span:nth-child(3),
      & > span:nth-child(11),
      & > span:nth-child(13) {
        right: -0.4rem;
      }

      & > span:nth-child(8),
      & > span:nth-child(9) {
        right: 27%;
      }

      & > span:nth-child(6),
      & > span:nth-child(7) {
        left: 27%;
      }

      & > span:nth-child(4),
      & > span:nth-child(5),
      & > span:nth-child(6),
      & > span:nth-child(7),
      & > span:nth-child(8),
      & > span:nth-child(9) {
        rotate: 90deg;
      }

      & > span:nth-child(4),
      & > span:nth-child(6),
      & > span:nth-child(8) {
        top: -1.2rem;
      }

      & > span:nth-child(5),
      & > span:nth-child(7),
      & > span:nth-child(9) {
        bottom: -1.2rem;
      }
    }
  }
}

.SelectedTable {
  border: 1px solid var(--color-primary);
}
