.PrimaryInputField {
  display: flex;
  // flex-direction: column;
  // gap: 1.2rem;

  label {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.4rem;
    color: var(--color-gray-600);
    margin-top: 1.6rem;
  }

  input {
    padding: 1.4rem 1.6rem;
    border-radius: 0.2rem;
    border: 1px solid var(--color-gray-200);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
}
