.DrawerCartContainer {
  z-index: 9;
  position: fixed;
  top: 10.8rem;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.4);
  overflow-y: scroll;
}

.DrawerCart {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 10.8rem;
  bottom: 0;
  right: 0;
  min-width: 50rem;
  background-color: #fff;
  padding: 2.5rem 2.4rem;

  &Order {
    &IDText {
      display: flex;
      align-items: center;
      gap: 1.6rem;
      color: var(--color-gray-400);
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7rem;
      margin-bottom: 0.8rem;
    }
    &ID {
      color: var(--color-gray-600);
      font-family: Inter;
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 2.9rem;
      margin-bottom: 1.4rem;
    }
    &Time {
      color: var(--color-gray-500);
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.6rem;
      margin-bottom: 2.6rem;
    }
  }
}
