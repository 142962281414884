.CustomStatus {
  position: absolute;
  top: 4rem;
  right: 2.4rem;
}

.Error {
  font-size: 1.6rem;
  color: red;
}

.PriceList {
  margin-top: 1rem;
  border-top: 1px solid var(--color-gray-light-1);
  padding-top: 3rem;
  overflow-y: auto;
  flex: 1;
}
