@use "scss/abstract/mixins" as *;

.LanguageSwitcher {
  display: inline-block;
  position: relative;
  z-index: 11;

  &Header,
  &ItemButton {
    color: var(--color-gray-600);
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4rem;
    border: none;
  }

  &Header {
    display: flex;
    align-items: center;
    padding: 1.4rem 1.2rem;
    border-radius: 0.4rem;
    background-color: var(--color-gray-50);
    cursor: pointer;

    svg {
      @include bp(smaller) {
        margin-right: 1.2rem;
      }
    }

    span {
      margin: 0 1.2rem;
      @include bp(smaller) {
        display: none;
      }
    }
  }

  &List {
    list-style: none;
    border-radius: 0.4rem;
    overflow: hidden;
    position: absolute;
    top: 100%;
    width: 100%;
    padding: 0.8rem 1.2rem;
    background-color: var(--color-gray-50);

    @include bp(smaller) {
      width: 10rem;
    }
  }

  &ListItem {
    width: 100%;
    display: inline-block;
  }

  &ItemButton {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    padding: 1.4rem 1.2rem;
    margin: 0 -1.2rem;
    background-color: var(--color-gray-50);
    width: calc(100% + 2.4rem);
    cursor: pointer;

    &:hover {
      background-color: var(--color-gray-100);
    }
  }
}

.SelectedLanguage {
  position: relative;

  &::after {
    content: "";
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: var(--color-primary);
    right: 1.6rem;
  }
}
