.Status {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: 0.2rem 0.8rem;
  border-radius: 5rem;

  & > span:first-of-type {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    display: inline-block;
  }
}
