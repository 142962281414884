.ReceiptBox {
  min-width: 70rem;
  position: relative;
}

.DownloadReceipt {
  padding: 1rem 1.4rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  // border: 1px solid var(--color-primary);
  background-color: #FAF9F9;
  color: #F38134;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 16rem;
  position: absolute;
  right: 15px;
  top: -80px;
} 