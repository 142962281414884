.splitBillContainer {
    background: white;
    min-height: 100vh;
    width: calc(100% - 40px);
    padding: 32px;
    border-radius: 12px;
  }
  
  .headerSection {
    margin-bottom: 32px;
  }
  
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .titleSection {
    display: flex;
    align-items: center;
    gap: 8px;
  
    h1 {
      font-size: 20px;
      font-weight: 600;
      color: #111827;
      margin: 0;
      line-height: 1;
    }
  }
  
  .dineInTag {
    background: #F7B614;
    color: #92400E;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
  }
  
  .orderIdText {
    font-size: 14px;
    color: #6B7280;
  }
  
  .tabContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
    border-bottom: 1px solid #E5E7EB;
    margin-bottom: 24px;
  }
  
  .tabButton {
    padding: 12px 0;
    background: none;
    border: none;
    font-size: 16px;
    color: #6B7280;
    cursor: pointer;
    position: relative;
    width: 100%;
  
    &.active {
      color: #111827;
      font-weight: 500;
  
      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 2px;
        background: #111827;
      }
    }
  }
  
  .summaryCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-bottom: 24px;
  }
  
  .card {
    background: #F9FAFB;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
  
    span {
      font-size: 14px;
      color: #6B7280;
      display: block;
      margin-bottom: 4px;
    }
  
    h3 {
      font-size: 20px;
      color: #111827;
      margin: 0;
      font-weight: 600;
    }
  }
  
  .splitCounterContainer {
    width: 100%;
    padding: 24px;
    margin-bottom: 24px;
  }
  
  .splitCounter {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    width: 100%;
    align-items: center;
  
    button {
      width: 100%;
      height: 40px;
      border: 1px solid #E5E7EB;
      background: white;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #6B7280;
  
      &:hover {
        background: #F9FAFB;
      }
    }
  
    input {
      width: 100%;
      height: 40px;
      border: 1px solid #E5E7EB;
      border-radius: 6px;
      text-align: center;
      font-size: 16px;
      color: #111827;
      padding: 0 12px;
  
      &:focus {
        outline: none;
        border-color: #F97316;
      }
    }
  }
  
  .splitHint {
    font-size: 14px;
    color: #6B7280;
    text-align: center;
    margin-top: 8px;
  }
  
  .tableContainer {
    margin-bottom: 24px;
  }
  
  .guestsTable {
    width: 100%;
    border-collapse: collapse;
  
    th {
      text-align: left;
      padding: 12px 16px;
      font-size: 14px;
      font-weight: 500;
      color: #6B7280;
      border-bottom: 1px solid #E5E7EB;
    }
  
    td {
      padding: 16px;
      border-bottom: 1px solid #E5E7EB;
      font-size: 14px;
      color: #111827;
    }
  }
  
  .tenderButton {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: #1F2937;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .statusCheckbox {
    width: 16px;
    height: 16px;
    border: 2px solid #D1D5DB;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
  
    &:checked {
      background-color: #F97316;
      border-color: #F97316;
      /* Using a background image for white checkmark */
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z'/%3E%3C/svg%3E");
      background-size: 12px;
      background-position: center;
      background-repeat: no-repeat;
    }
  
    /* Remove default checkbox appearance */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  
    &:focus {
      outline: none;
    }
  }
  
  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 24px;
  }
  
  .cancelBtn {
    width: 100%;
    padding: 12px 24px;
    background: white;
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
  
    &:hover {
      background: #F9FAFB;
    }
  }
  
  .confirmBtn {
    width: 100%;
    padding: 12px 24px;
    background: #F97316;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  
    &:hover {
      background: #EA580C;
    }
  
    &:disabled {
      background-color: #cccccc;  // Light gray background
      color: #666666;             // Darker gray text
      cursor: not-allowed;
      opacity: 0.7;
      pointer-events: none;       // Prevents any hover effects
      border: none;              // Remove any border when disabled
      
      &:hover {
        background-color: #cccccc;  // Prevent hover effect when disabled
        transform: none;            // Prevent any hover animations
      }
    }
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-y: auto;
    padding: 20px;
  }
  
  .modalContent {
    background: white;
    padding: 32px;
    border-radius: 12px;
    width: 100%;
    max-width: 550px;
    max-height: 90vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    margin: auto;
    position: relative;
  
    h3 {
      position: sticky;
      top: 0;
      background: white;
      padding: 16px 0;
      margin: 0 0 24px 0;
      font-size: 18px;
      color: #111827;
      text-align: center;
      font-weight: 600;
      z-index: 1;
    }
  }
  
  .tenderGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    width: 100%;
    margin-bottom: 24px;
    overflow-y: auto;
    padding: 0 16px;
  }
  
  .tenderOption {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 120px;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    background: white;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    &:hover {
      border-color: #F97316;
      background: #FFF7ED;
      transform: translateY(-3px);
    }
  
    img {
      height: 24px;
      width: auto;
      object-fit: contain;
    }
  
    span {
      font-size: 14px;
      color: #374151;
      font-weight: 500;
      text-align: center;
    }
  
    &.selected {
      background-color: rgba(0, 0, 0, 0.1);
      border: 2px solid #007bff;
    }
  }
  
  .modalActions {
    position: sticky;
    bottom: 0;
    background: white;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 16px 0;
    margin-top: 16px;
    border-top: 1px solid #E5E7EB;
    z-index: 1;
  
    button {
      padding: 12px 24px;
      border-radius: 6px;
      font-size: 14px;
      cursor: pointer;
      font-weight: 500;
      flex: 1;
      margin: 0 8px;
      transition: background 0.3s ease;
    }
  }
  
  .cancelBtn {
    background: white;
    border: 1px solid #E5E7EB;
    color: #374151;
  
    &:hover {
      background: #F9FAFB;
    }
  }
  
  .confirmBtnModal {
    background: #1F2937;
    border: none;
    color: white;
  
    &:hover {
      background: #111827;
    }
  }
  
  .selectedTender {
    background-color: white;
    border-radius: 50px;
    border: 1px solid #111827;
    color: #111827;
    cursor: pointer;
    padding: 10px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .inputField {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s;
  
    &:focus {
      border-color: #007bff;
      outline: none;
    }
  
    &::placeholder {
      color: #aaa;
    }
  }
  
  .customAmountInput {
    position: relative;
    width: 100%;
    height: 40px;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 500;
    color: #2c3345;
    background-color: #ffffff;
    border: 1.5px solid #e2e8f0;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    text-align: right;
    
    // Currency prefix
    &::before {
      content: 'SAR';
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      color: #64748b;
      font-size: 14px;
      font-weight: 500;
    }
  
    // Placeholder styling
    &::placeholder {
      color: #94a3b8;
      font-weight: 400;
    }
  
    // Focus state
    &:focus {
      outline: none;
      border-color: #3b82f6;
      box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
    }
  
    // Hover state
    &:hover {
      border-color: #cbd5e1;
    }
  
    // Disabled state
    &:disabled {
      background-color: #f8fafc;
      cursor: not-allowed;
      opacity: 0.7;
    }
  
    // Remove spinner buttons for number input
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
  
  // Container for the input field
  .inputContainer {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  
    // Error state
    &.hasError {
      .customAmountInput {
        border-color: #ef4444;
        
        &:focus {
          box-shadow: 0 0 0 3px rgba(239, 68, 68, 0.1);
        }
      }
    }
  }
  
  // Table cell containing input
  td {
    .inputContainer {
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }
  } 