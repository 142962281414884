.PaymentChannelCard,
.PaymentChannelCardSelected {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 2px solid transparent;
  width: 16.5rem;
  height: 16.5rem;
  background-color: #fff;
  border-radius: 0.8rem;
  box-shadow: 0px 10px 15px -3px #0000001a;
  box-shadow: 0px 4px 6px -2px #0000000f;
  color: var(--color-gray-400);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4rem;
  cursor: pointer;

  & > img {
    margin-bottom: 3.2rem;
  }

  & > svg {
    position: absolute;
    right: 1.6rem;
    top: 1rem;
    color: var(--color-primary);
  }

  & > span {
    position: absolute;
    bottom: 1.2rem;
  }
}

.PaymentChannelCardSelected {
  border: 2px solid var(--color-primary);
}

.PaymentSplitBillCard,
.PaymentSplitBillCardSelected {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 2px solid transparent;
  width: 16.5rem;
  height: 16.5rem;
  background-color: #fff;
  border-radius: 0.8rem;
  box-shadow: 0px 10px 15px -3px #0000001a;
  box-shadow: 0px 4px 6px -2px #0000000f;
  color: var(--color-gray-400);
  cursor: pointer;

  svg {
    position: absolute;
    right: 1.6rem;
    top: 1rem;
    color: var(--color-primary);
  }
}

.PaymentChannelCardSelected {
  border: 2px solid var(--color-primary);
}
.PaymentSplitBillCardSelected {
  border: 2px solid var(--color-primary);
}

.Percentage {
  font-size: 3rem;
  font-weight: 600;
}

.Name {
  font-size: 1.4rem;
  font-weight: 400;
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Percentage {
  font-size: 24px;
  margin-bottom: 4px;
}

.Name {
  font-size: 14px;
}

.Disabled {
  pointer-events: none;
  user-select: none;
}

