@import "./abstract/functions";
@import "./abstract/mixins";
@import "./abstract/variables";

@import "./base/base";
@import "./base/animations";
@import "./base/fonts";
@import "./base/utilities";

/* width */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
