.CustomModal {
  width: min(100%, 57rem);
}

.ModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9.6rem;

  &ImageBox {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 0.4rem;
    background-color: #fff3eb;

    & > img {
      max-height: 2.4rem;
    }
  }

  & > button {
    background-color: transparent;
    border: transparent;
    cursor: pointer;

    & > svg {
      pointer-events: none;
    }
  }
}

.ChargesInfoList {
  list-style: none;

  &Item {
    & > span:first-of-type,
    & > span:last-of-type {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.6rem;
    }

    & > span:first-of-type {
      color: var(--color-gray-400);
    }
    & > span:last-of-type {
      color: var(--color-gray-600);
    }
  }

  &Item,
  &ItemLarge {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    min-width: min(100%, 48rem);

    &:nth-of-type(odd) {
      margin-bottom: 1rem;
    }
    &:nth-of-type(even) {
      margin-bottom: 2.4rem;
      direction: rtl;
    }
  }

  &ItemLarge {
    & > span:first-of-type,
    & > span:last-of-type {
      font-size: 1.8rem;
      line-height: 1.8rem;
    }

    & > span:first-of-type {
      font-weight: 500;
      color: var(--color-gray-400);
    }
    & > span:last-of-type {
      font-weight: 600;
      color: var(--color-gray-800);
    }
  }

  & > hr {
    border: none;
    height: 1px;
    background-color: var(--color-gray-200);
    margin-bottom: 2.4rem;
  }
}

.ProceddBtn {
  width: 100%;
  padding: 2rem 1.6rem;
  justify-content: space-between;
  margin-top: 7.2rem;
}
