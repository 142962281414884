.mb-1-2 {
  margin-bottom: 1.2rem;
}

.mb-1-6 {
  margin-bottom: 1.6rem;
}

.mb-3-2 {
  margin-bottom: 3.2rem;
}

.mt-1-6 {
  margin-top: 1.6rem;
}

.mt-4-8 {
  margin-top: 4.8rem;
}

.mt-8 {
  margin-top: 8rem;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-a {
  margin-right: auto;
}

.text-end {
  text-align: end;
}
.mb-0 {
  margin-bottom: 0;
}
