.SearchInput {
  width: 100%;
  position: relative;
  background-color: red;
  border-radius: 0.5rem;
  overflow: hidden;
  color: var(--color-gray-light-2);

  input {
    font-size: 1.6rem;
    width: 100%;
    padding: 1.6rem;
    border: none;
    outline: none;
    padding-left: 5.6rem;
    font-weight: 400;
  }

  svg {
    position: absolute;
    top: 1.2rem;
    left: 1.6rem;
    pointer-events: none;
  }
}
