.MainCart {
  display: grid;
  grid-template-columns: 100%;
  // grid-template-rows: auto 1fr;
  grid-template-rows: 1fr auto;
  overflow-y: hidden;
  height: 100%;
  max-height: calc(100vh - 15rem);
}

.Cart {
  background-color: #fff;
  border-radius: 0.8rem;
  position: fixed;
  padding: 1.6rem 2rem;
  align-self: start;
  height: calc(100vh - 11rem);
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 2.4rem;
  min-width: 50rem;
  top: 20%;
  @media (min-width: 2500px) {
    top: 12% !important;
  }
  @media (max-width: 1100px) {
    top: 12% !important;
  }
  @media (max-width: 850px) {
    top: 12% !important;
  }
  @media (max-width: 780px) {
    top: 15% !important;
  }

  &Header {
    display: flex;
    align-items: center;
    margin-bottom: 0.4rem;
  }
}

.Order {
  &Id,
  &Time {
    margin-bottom: 1.4rem;
  }

  &Id {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.9rem;
    color: var(--color-gray-800);
  }

  &IdText {
    text-transform: uppercase;
    color: var(--color-gray-400);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem;

    &:last-of-type {
      margin-left: 1rem;
    }
  }

  &Time {
    color: var(--color-gray-500);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
}

.CartTypeButtons {
  display: flex;
  align-items: flex-start;
  text-transform: capitalize;
  flex-wrap: wrap;
  gap: 0.6rem;
}

.ActionButtons {
  display: flex;
  flex-wrap: wrap;
  max-width: 40rem;
  gap: 1rem;
}

.PriceList {
  margin-top: 1rem;
  border-top: 1px solid var(--color-gray-light-1);
  padding-top: 1rem;
  overflow-y: auto;
  flex: 1;
}

.CouponTab {
  display: flex;
  background-color: var(--color-primary-light);
  padding: 1.6rem;
  border-radius: 0.8rem;
  margin: 2.4rem 0;

  .CouponText {
    margin-right: auto;
    font-size: 1.6rem;
  }

  .CouponButton {
    background-color: transparent;
    color: var(--color-primary);
    border: none;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2rem;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.PaymentTab {
  margin-bottom: 2rem;
}

.Payment {
  font-size: 1.4rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.IconRightButton {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary-light);
  color: var(--color-primary);
  padding: 1.6rem 4.7rem;
  border-radius: 0.4rem;
  transition: background-color 0.2s ease-out;
  cursor: pointer;

  svg {
    margin-left: 0.8rem;
  }

  &:hover {
    background-color: var(--color-primary);
    color: #fff;
  }
}

.CheckoutButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.AddServicesBtn {
  border: none;
  // margin-left: auto;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2rem;
  width: 48%;
  background-color: #f3781f;
  color: #fff;
  padding: 0.7rem;
}
.EditServicesBtn {
  border: 1px solid #f3781f;
  // margin-left: auto;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2rem;
  width: 48%;
  padding: 0.7rem;
  background-color: transparent;
}

.AcceptBtn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ProceedBtn {
  width: 48%;
  display: flex;
  justify-content: space-between;
}
.CancelBtn {
  width: 48%;
  background-color: transparent;
  border: 1px solid #f3781f;
  color: #f3781f;
}

.AcceptBtn {
  justify-content: center;
}
