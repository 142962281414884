@use "scss/abstract/mixins" as *;

.Dishes {
  max-width: calc(100vw - 18.4rem);
  display: grid;
  grid-template-columns: 1fr minmax(max-content, 50rem);
  grid-gap: 1rem;
  position: relative;
  // height: 100vh;
  // padding-bottom: 50px;
  @media (max-width: 850px) {
    max-width: calc(100vw - 1.4rem) !important;
  }
}

.Heading2 {
  margin: 2.8rem 0;
  font-size: 2.4rem;
}

.AllDishes {
  gap: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 26rem));
  // padding-bottom: 50px;
}

.DishesBox {
  max-width: calc(100vw - 68.4rem) !important;
  // @media (max-width: 800px) {
  //   max-width: calc(100vw - 58.4rem) !important;
  // }
  @media (max-width: 850px) {
    max-width: calc(100vw - 20.4rem) !important;
  }

  // height: 100vh;
  // padding-bottom: 50px;
}

.Error {
  text-align: center;
  color: red;
}

.CartBox {
  max-height: calc(100vh - 16.2rem);
  position: fixed;
  right: 1.6rem;
  width: 50rem;
}

.FilterTab {
  @include bp(larger-1) {
    display: none;
  }
}

.rightPanel {
  width: 50%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-columns: auto 1fr;
}
