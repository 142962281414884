.ReceiptPage {
  display: flex;
  // align-items: flex-start;
  gap: 2rem;
}

.CartBox {
  flex: 1;
  max-width: 61rem;
  // height: 60vh !important;
  // overflow-y: auto;
  // background-color: red;
}

.Cart {
  padding: 3.2rem 3.4rem;
  border-radius: 0.4rem;
  background-color: #fff;
  box-shadow: 0px 4px 6px -1px #0000001a;
  box-shadow: 0px 2px 5px 0px #0000000f;
  // height: 100%;
  max-height: 75vh;
  overflow-y: auto;
  // min-height: 30vh;

  &Header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.6rem;

    & > p {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.6rem;
      color: var(--color-gray-800);
    }

    & > img {
      max-height: 3.2rem;
    }
  }
}

.Order {
  &TypesAndDate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &TypeList {
    list-style: none;
    display: flex;
  }

  &TypeListItem,
  &TypeListItemSelected {
    display: inline-block;
    padding: 1.2rem 1.6rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2rem;
    color: var(--color-gray-800);
    border-radius: 0.4rem;
    cursor: pointer;
  }

  &TypeListItemSelected {
    background-color: var(--color-primary);
    color: #fff;
  }

  &Date,
  &TableText,
  &Persons {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.2rem;
    color: var(--color-gray-800);
  }

  &TableText {
    text-align: end;
    margin-bottom: 1.6rem;
  }

  &Persons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;
  }
}

.ItemList {
  list-style: none;
  max-height: 75%;
}

.Item {
  padding: 2.4rem 0;
  display: grid;
  grid-template-columns: 1fr 7fr 3fr 1fr;
  border-bottom: 1px solid var(--color-gray-300);

  &:first-child {
    border-top: 1px solid var(--color-gray-300);
  }

  &Index {
    margin-right: 2.4rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6rem;
    color: var(--color-gray-600);
  }
  &Name {
  }
  &Size {
    grid-column: 2/3;
    grid-row: 2/3;
  }
  &DiscountPrice {
  }
  &OriginalPrice {
    grid-row: 2/3;
    grid-column: 3/4;
    text-decoration: line-through;
    text-align: end;
  }
  &Index {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6rem;
    color: var(--color-gray-800);
    margin-bottom: 0.6rem;
  }
  &Quantity {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6rem;
    color: var(--color-gray-800);
    margin-bottom: 0.6rem;
  }
  &Name,
  &DiscountPrice {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.6rem;
    color: var(--color-gray-800);
    margin-bottom: 0.6rem;
  }

  &Size,
  &OriginalPrice {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.2rem;
    color: var(--color-gray-400);
  }
}

.CartActions {
  margin-top: 1.8rem;
  display: flex;
  gap: 1.2rem;
  justify-content: flex-end;

  & > button {
    background: #f3781f1a;
    color: var(--color-primary);
    display: flex;
    gap: 1.2rem;
    align-items: center;
    border: 1px transparent;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
    padding: 1rem 2.4rem;
    cursor: pointer;
  }
}

.ReceiptBox {
  min-width: 30rem;
}

.ReceiptLanguageSwitcher {
  background-color: #fff;
  padding: 2rem 1.6rem;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  width: 100%;

  &Btn,
  &BtnSelected {
    padding: 1rem 0;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.4rem;
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--color-gray-600);
    cursor: pointer;

    &:last-of-type {
      margin-left: 1.6rem;
    }
    &:first-of-type {
      margin-left: auto;
    }
  }

  &BtnSelected {
    border-bottom: 1px solid var(--color-primary);
  }

  & > span {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.8rem;
    color: var(--color-gray-800);
  }
}

.Receipt {
  margin-top: 1.9rem;
  padding: 2.4rem 1.2rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.4rem;
  // max-height: 60vh;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  // font-family: "Inter";
  font-family: "Merchant";
}

.RestaurantNameLg1 {
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: var(--color-gray-400);
  margin-bottom: 0.8rem;
  font-family: "Merchant";
}

.RestaurantNameLg2 {
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 1rem;
  color: var(--color-gray-400);
  margin-bottom: 1.2rem;
}

.VAT {
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: var(--color-gray-400);
  margin-bottom: 1.6rem;
}

.Welcome {
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: var(--color-gray-600);
  margin-bottom: 0.8rem;
}

.TaxText {
  display: flex;
  align-items: center;
  color: var(--color-gray-400);
  gap: 1.2rem;
  margin-bottom: 1.2rem;

  &Lg1 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.1rem;
  }

  &Lg2 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 0.6rem;
  }
}

.Id {
  margin-bottom: 1.6rem;
  border: 1px solid var(--color-gray-300);
  padding: 1.2rem 0.8rem;
  color: var(--color-gray-600);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.2rem;
}

.DateBox {
  color: var(--color-gray-400);
  margin-bottom: 1.2rem;
}

.DateBox,
.NameBox,
.FlexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.DateEng {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.1rem;
}
.DateAr {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
}

.NameBox {
  color: var(--color-gray-600);
}
.NameEng {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.2rem;
}

.NameAr {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 0.6rem;
}

.LineBreaker {
  border: none;
  height: 1px;
  background-color: var(--color-gray-200);
  width: 100%;
  margin: 1.6rem 0;
}

.ReceiptTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.2rem;

  th {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1rem;
    color: var(--color-gray-600);
    padding-bottom: 1.2rem;
  }
  tr {
    padding-top: 15px !important;
  }

  td {
    color: var(--color-gray-500);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1rem;
    padding-bottom: 1.2rem;
    vertical-align: top;
    border-bottom: 1px dashed var(--color-gray-200);
  }

  th,
  td {
    text-align: right;

    &:first-child {
      text-align: left;
    }
  }
}

.ItemNameCell {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  gap: 0.8rem;

  & > span:last-of-type {
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.FlexBox {
  font-weight: 400;
  color: var(--color-gray-500);

  & > span:first-of-type {
    line-height: 1.1rem;
    font-size: 1.3rem;
    font-family: Arial, Helvetica, sans-serif;
    // padding-bottom: 0.2rem;
  }

  & > span:last-of-type {
    line-height: 1.1rem;
    font-size: 1.3rem;
    font-family: Arial, Helvetica, sans-serif;
  }

  & > :not(:last-of-type) {
    margin-bottom: 1.2rem;
  }
}

.BarCodeImg {
  margin-top: 1.2rem;
}

.PrintBtn,
.ConfirmAndPrintBtn {
  margin-top: 1.2rem;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7rem;
}

.ConfirmAndPrintBtn {
}

.PrintBtn {
  background-color: transparent;
  color: var(--color-primary);
  border: 1px solid currentColor;
}
.CouponTab {
  display: flex;
  background-color: var(--color-primary-light);
  padding: 1.6rem;
  border-radius: 0.8rem;
  margin: 2.4rem 0;

  .CouponText {
    margin-right: auto;
    font-size: 1.6rem;
  }

  .CouponButton {
    background-color: transparent;
    color: var(--color-primary);
    border: none;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2rem;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
.CartTypeButtons {
  display: flex;
  align-items: flex-start;
  text-transform: capitalize;
  flex-wrap: wrap;
  gap: 0.6rem;
}
.PriceList {
  // margin-top: 2.6rem;
  border-top: 1px solid var(--color-gray-light-1);
  padding-top: 1rem;
  overflow-y: auto;
  flex: 1;
}
