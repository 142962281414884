.Sidebar {
  flex: 1 !important;
  padding: 3.2rem 2rem;
  min-height: 100vh;
  overflow-y: auto;
  // width: 20vw;
  // max-width: 27.7rem !important;
  width: 15% !important;
  // max-width: var(--sidebar-max-width) !important;
  background-color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  z-index: 800;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  // max-height: calc(100vh - 10.8rem) !important;

  &ToggleHead {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 1.8rem;
    margin-bottom: 4.2rem;
    cursor: pointer;
    transition: margin 0.2s;

    svg {
      color: var(--color-gray-50);
    }

    span {
      color: var(--color-gray-100);
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.6rem;
    }
  }

  &Header {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 1.8rem;
    grid-row-gap: 0.4rem;
    margin-bottom: 3.8rem;
    align-items: center;

    svg {
      grid-row: 1 / span 2;
      color: var(--color-gray-50);
      // transition: margin 0.2s;
    }

    label {
      text-transform: uppercase;
      color: var(--color-gray-300);
      font-family: Inter;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
    }
    a {
      display: inline-block;
      color: var(--color-gray-100);
      font-family: Inter;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.6rem;
      text-decoration: none;
      &:hover {
        color: var(--color-primary);
      }
    }
  }
}

.SidebarNav {
  &List {
    list-style: none;
    padding: 1.8rem 0;
  }
  &Item {
    display: block;
    width: 100%;
  }
  &Link {
    // transition: padding 0.2s, background-color 0.1s;

    &:hover svg,
    &:hover span {
      color: var(--color-primary);
    }
  }

  &Link,
  &LinkActive {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 1.4rem;
    padding: 2rem 0;

    svg {
      color: var(--color-gray-50);
    }

    span {
      color: var(--color-gray-100);
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.6rem;
    }
  }

  &LinkActive {
    background-color: var(--color-primary);
    padding: 1rem;
  }
}

.Breaker,
.LinkBreaker {
  display: inline-block;
  border: none;
  width: 100%;
  background-color: var(--color-gray-700);
  height: 1px;
}

.LinkBreaker {
  margin: 1.8rem 0;
}

.UserBox {
  margin-top: auto;
  hr {
    margin-bottom: 3.2rem;
  }

  &Label,
  &Status {
    color: var(--color-gray-300);
    text-transform: uppercase;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  &Label {
    display: block;
    font-weight: 500;
    margin-bottom: 0.8rem;
  }
  &Status {
    display: inline-block;
    font-weight: 600;
    letter-spacing: 0.06em;
    padding: 1rem;
    background-color: var(--color-gray-700);
    margin-bottom: 3.2rem;
  }
}

.User {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  color: var(--color-gray-50);
  cursor: pointer;

  &Img {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    transition: margin 0.2s;
  }

  &Name {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.6rem;
    text-transform: capitalize;
  }

  svg {
    margin-left: auto;
  }
}

.SidebarCollapsed {
  max-width: var(--sidebar-collapsed-max-width);
}

.SidebarCollapsed .Sidebar {
  &ToggleHead span,
  &Header label,
  &Header a {
    display: none;
  }

  &ToggleHead svg,
  &Header svg {
    margin-left: 1.2rem;
  }

  &Header {
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      position: absolute;
      bottom: -1.4rem;
      left: 50%;
      transform: translateX(-30%);
      background-color: #8cff82;
    }
  }
}

.SidebarCollapsed .SidebarNav {
  &LinkActive,
  &Link {
    span {
      display: none;
    }
  }

  &Link svg {
    margin-left: 1rem;
  }
}

.SidebarCollapsed .UserBox {
  &Label,
  &Status {
    display: none;
  }
}

.SidebarCollapsed .User {
  &Img {
    margin-left: 0.4rem;
  }

  &Name,
  svg {
    display: none;
  }
}

html[dir="rtl"] {
  .User svg {
    margin-left: 0;
    margin-right: auto;
  }

  .SidebarCollapsed .Sidebar {
    &ToggleHead svg,
    &Header svg {
      margin-right: 1.2rem;
      margin-left: 0;
    }

    &Header {
      &::after {
        bottom: -1.4rem;
        right: 50%;
        transform: translateX(20%);
      }
    }
  }

  .SidebarCollapsed .SidebarNav {
    &Link svg {
      margin-left: 0;
      margin-right: 1rem;
    }
  }

  .SidebarCollapsed .User {
    &Img {
      margin-left: 0;
      margin-right: 0.4rem;
    }
  }
}
